<template>
  <div ref="fullScreenScore">
    <div :class="fullscreen ? 'container' : ''">
      <div class="mb-4">
        <v-btn
          color="primary"
          small
          text
          type="button"
          class="mr-4"
          @click="toggle"
        >
          <v-icon :small="!fullscreen ? true : false" class="mr-2"
            >{{ !fullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit" }}
          </v-icon>
          {{ !fullscreen ? "Fullscreen" : "Exit" }}</v-btn
        >
        <v-btn
          v-if="!fullscreen"
          color="grey darken-4"
          dark
          small
          text
          type="button"
          @click="toggle"
        >
          <v-icon small class="mr-2"> mdi-email </v-icon> Send to
          Prospect</v-btn
        >
      </div>
      <div v-if="!loading">
        <plangap-risk-score
          :env="env"
          :advisor-id="advisorId"
          :id="advisorId"
          source="rs-intel-presenter"
        ></plangap-risk-score>
      </div>
      <div v-else>
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { api as fullscreen } from "vue-fullscreen";

export default {
  data() {
    return {
      advisorId: null,
      env: null,
      loading: false,
      fullscreen: false,
      teleport: false,
      pageOnly: true,
    };
  },
  computed: {},
  created() {
    this.env = process.env.VUE_APP_SCORE_ENV;
    console.log("env", this.env);
    console.log("const advisorId = this.$route.params.id;", this.advisorId);
  },
  mounted() {
    this.loading = true;
    let surveyEnv;
    if (this.env && this.env === "production") {
      surveyEnv = ""; // bc prod survey bucket is: pgriskscoresurvey with no env at end of string
    } else {
      surveyEnv = this.env;
    }

    const existingPolyfillScriptTag = document.getElementById(
      "webComponentPolyfill"
    );

    if (!existingPolyfillScriptTag) {
      let webComponentPolyfill = document.createElement("script");

      webComponentPolyfill.setAttribute(
        "src",
        "https://unpkg.com/@webcomponents/webcomponentsjs@latest/webcomponents-loader.js"
      );
      webComponentPolyfill.setAttribute("id", "webComponentPolyfill");

      document.head.appendChild(webComponentPolyfill);
    }

    const existingScoreSurveyScriptTag =
      document.getElementById("scoreSurveyScript");
    console.log("existingScoreSurveyScriptTag", existingScoreSurveyScriptTag);
    if (!existingScoreSurveyScriptTag) {
      let scoreSurveyScript = document.createElement("script");

      scoreSurveyScript.setAttribute(
        "src",
        `https://pgriskscoresurvey${surveyEnv}.s3.amazonaws.com/dist/plangap-risk-score.min.js`
      );
      scoreSurveyScript.setAttribute("id", "scoreSurveyScript");

      document.head.appendChild(scoreSurveyScript);

      scoreSurveyScript.onload = () => {
        console.log("WELLE");
        // script has loaded, you can now use it safely
        if (this.$route.params.id) {
          this.advisorId = this.$route.params.id;
          this.loading = false;
        } else {
          this.loading = false;
        }
      };
    } else {
      console.log("WELLERRRR");
      if (this.$route.params.id) {
        this.advisorId = this.$route.params.id;
        this.loading = false;
      } else {
        this.loading = false;
      }
    }
  },
  methods: {
    async toggle() {
      console.log(
        'this.$el.querySelector("#fullScreenScore")',
        this.$refs.fullScreenScore
      );
      await fullscreen.toggle(this.$refs.fullScreenScore, {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
      });
      this.fullscreen = fullscreen.isFullscreen;
    },
  },
};
</script>

<style lang="scss" scoped></style>
